export const products = [
  {
    name: "VV Traditional Pure",
    type: "Οικιακή Αντίστροφη Όσμωση",
    img: "https://www.veluda.com/assets/pages/main_cbe95-vv-traditional-pure.png",
    description: [
      "Πρόκειται για ένα αξιόπιστο σύστημα αντίστροφης όσμωσης 5 σταδίων κλασσικού design , με ενσωματωμένο πιεστικό μηχάνημα.",
      "Το πιο δημοφιλές σύστημα οικιακής αντίστροφης όσμωσης στην Ελληνική αγορά.",
      "Καταλαμβάνει λίγο χώρο και τοποθετείται εύκολα κάτω από τον πάγκο της κουζίνας.",
    ],
    info: [
      "Διαστάσεις:  36x22x45 cm",
      "Βάρος: 14kg",
      "Διαστάσεις δοχείου: 23x23x36 cm",
      "Μέγιστη πίεση: 3 bar",
      "Μέγιστη ροή:  0.15 lt/min",
      "Μέγιστη Θερμοκρασία: 38 0C",
      "Στάδια φίλτρανσης : 5",
      "Αντικατάσταση φίλτρων: 1 έτος",
    ],
    available: true,
    group: 1,
  },
  {
    name: "V.PRO 400",
    img: "https://www.veluda.com/assets/pages/main_26aa6-vpro-400.png",
    type: "Επαγγελματική Αντίστροφη Όσμωση",
    description: [
      "Πρόκειται για ένα επαγγελματικό σύστημα αντίστροφης όσμωσης 5 σταδίων με αντλία, και παραγωγή 380lt την ημέρα.",
      "Eιδικά σχεδιασμένο για να καλύπτει ανάγκες HO.RE.CA.",
    ],
    info: [
      "Διαστάσεις:  36x22x47 cm",
      "Βάρος: 16kg",
      "Μέγιστη πίεση: 3 bar",
      "Μέγιστη ημερήσια παραγωγή: 380 lt",
      "Μέγιστος χρόνος λειτουργίας: 7 h/day ",
      "Αναλογία καθαρού/αποχέτευσης: 1/1.5",
      "Μέγιστη Θερμοκρασία: 38 0C",
      "Στάδια φίλτρανσης : 5",
      "Αντικατάσταση φίλτρων: 10000lt",
    ],
    available: true,
    group: 1,
  },
  {
    name: "10 Blue Housing",
    img: "https://res.cloudinary.com/dtekdjcan/image/upload/v1680672637/veloudios.gr/product%20images/bhousing10_zmsgei.jpg",
    type: "Φίλτρο κεντρικής παροχής 10''",
    description: [
      "Μπλέ φιλτροθήκη 10'' με είσοδο-έξοδο 1/2'' και 3/4''",
      "Συμβατή με όλα τα φίλτρα 10''",
      "Στην συσκευασία περιλαμβάνει βάση στήριξης και κλειδί σύσφιξης.",
    ],
    info: [
      "Υλικό:Reinforced Polypropylene",
      "Eίσοδος-Εξοδος: Ορειχάλκινη 1/2'' & 3/4",
      "Μέγιστη πίεση: 6 bar (600 kpa)",
      "Θερμοκρασία: 3-43 °C ",
      "Περιέχει βαλβίδα εξαέρωσης",
      "Λάστιχο στεγάνωσης: EPDM",
    ],
    available: true,
    group: 2,
  },
  {
    name: "20 Blue Housing",
    img: "https://res.cloudinary.com/dtekdjcan/image/upload/v1680672637/veloudios.gr/product%20images/bhousing20_iugw0c.jpg",
    type: "Φίλτρο κεντρικής παροχής 20''",
    description: [
      "Μπλέ φιλτροθήκη 20'' με είσοδο-έξοδο 3/4''",
      "Συμβατή με όλα τα φίλτρα 20''",
      "Στην συσκευασία περιλαμβάνει βάση στήριξης και κλειδί σύσφιξης.",
    ],
    info: [
      "Υλικό:Reinforced Polypropylene",
      "Eίσοδος-Εξοδος: Ορειχάλκινη 3/4",
      "Μέγιστη πίεση: 6 bar (600 kpa)",
      "Θερμοκρασία: 3-43 °C ",
      "Περιέχει βαλβίδα εξαέρωσης",
      "Λάστιχο στεγάνωσης: EPDM",
    ],
    available: true,
    group: 2,
  },
  {
    name: "Φίλτρο συμπαγούς άνθρακα",
    img: "https://www.veluda.com/assets/pages/main_a7c77-cto-bb.jpg",
    type: "Carbon Block (CTO)",
    description: [
      "Τα φίλτρα συμπαγούς ενεργού άνθρακα απο φλοιό καρύδας που είναι  100% φυσικό προϊόν. Ο συμπαγής ενεργός  άνθρακας είναι στην ουσία ένας τοίχος από τον οποίο πρέπει να περάσει το νερό για να φιλτραριστεί. Το νερό αναγκάζεται να διαπεράσει το φίλτρο και φιλτράρεται από διάφορες βλαβερές ουσίες όπως για παράδειγμα το χλώριο, το χλωροφόρμιο, τα αιωρούμενα σωματίδια, την άμμο, τη σκουριά, τη λάσπη, τα χώματα, τον ολικό οργανικό άνθρακα, τις βιομηχανικές οργανικές ενώσεις, τις διαλυμένες οργανικές ενώσεις, το οργανικό χρώμα, το διαλυμένο σίδηρο, το μαγγάνιο και διάφορα άλλα. Διατίθενται σε μεγάλη ποικιλία μεγεθών και ταιριάζουν στις περισσότερες φιλτροθήκες νερού του εμπορίου ανάλογα τις διαστάσεις τους .",
    ],
    info: [
      "Υλικό: Συμπαγής Άνθρακας απο φλοιό καρύδας",
      "Μέγιστη πίεση: 7.5 bar",
      "Μέγιστη θερμοκρασία 52 C°",
      "Πόρος Φίλτρανσης: 10micron",
      "Εσωτερική διάμετρος: 28mm",
      "Εξωτερική διάμετρος: 70mm (slim) & 113mm (big blue)",
    ],
    available: true,
    group: 2,
  },
  {
    name: "Φίλτρο νήματος",
    img: "https://www.veluda.com/assets/pages/main_6c1fc-ps.jpeg",
    type: "Φίλτρο νήματος (PS)",
    description: [
      "Το φίλτρο νήματος (PS) αποτελείται απο περιπεπλεγμένες ίνες πολυπροπυλενίου.",
      "Τα φίλτρα νήματος είναι  μη τοξικά και απολύτως κατάλληλα για πόσιμο νερό.",
      "Περιέχουν ένα εσωτερικό δακτύλιο πολυπροπυλενίου πάνω στον οποίο έχουν πλεχθεί ομοιογενώς ίνες πολυπροπυλενίου.",
      "Αφαιρεί σωματίδιά, ιζήματα, άμμο, σκουριά, χώμα, κα. Διατίθενται σε μεγάλη ποικιλία μεγεθών και χαρακτηρισμών micron και ταιριάζουν στις περισσότερες φιλτροθήκες νερού του εμπορίου ανάλογα τις διαστάσεις τους. Διακρίνονται και ανάλογα τον πόρο φίλτρανσης (micron),δηλαδή το μέγεθος των σωματιδίων που θέλουμε να αφαιρέσουμε απο το νερό. Όσο μικρότερος είναι ο πόρος φίλτρανσης του τόσο καλύτερο φιλτράρισμα του νερού γίνεται.",
    ],
    info: [
      "Υλικό: Ίνες Πολυπροπυλενίου",
      "Μέγιστη πίεση: 6 bar",
      "Μέγιστη θερμοκρασία 60 C°",
      "Πόρος Φίλτρανσης: 1-50micron",
      "Efficiency 80 %",
      "Εσωτερική διάμετρος: 28mm",
      "Εξωτερική διάμετρος: 64mm ",
    ],
    available: true,
    group: 2,
  },
  {
    name: "Φίλτρο πολυπροπυλενίου",
    img: "https://www.veluda.com/assets/pages/main_b78f4-pp.jpg",
    type: "Πρόφιλτρα πολυπροπυλενίου PP",
    description: [
      "Πρόφιλτρα πολυπροπυλενίου υψηλής απόδοσης, ιδανικά για αφαίρεση στερεών σωματιδίων, θολότητας, άμμου, σκουριάς και άλλων αιωρημάτων .Τα φίλτρα πολυπροπυλενίου είναι  μη τοξικά και απολύτως κατάλληλα για πόσιμο νερό επίσης είναι είναι τραχύ και ασυνήθιστα ανθεκτικό σε πολλά χημικά διαλυτικά, οξέα και βάσεις. Η μεγάλη ποικιλία σε μέγεθος και σε πόρο φίλτρανσης τα καθιστούν ιδανικά για οικιακά χρήση ,στον πάγκο της κουζίνας μας ή την κεντρική παροχή του σπιτιού . Χρημοποιούνται συνήθως ώς πρώτο στάδιο φίλτρανσης πριν απο τα φίλτρα άνθρακα σε συστοιχίες φίλτρων ή σε αντίστροφες οσμώσεις, και αφαιρώντας τα αιωρούμενα στερεά αυξάνουν τον χρόνο ζωής των επόμενων φίλτρων. ",
      "Διατίθενται σε μεγάλη ποικιλία μεγεθών και χαρακτηρισμών micron και ταιριάζουν στις περισσότερες φιλτροθήκες νερού του εμπορίου ανάλογα τις διαστάσεις τους. Διακρίνονται και ανάλογα τον πόρο φίλτρανσης (micron),δηλαδή το μέγεθος των σωματιδίων που θέλουμε να αφαιρέσουμε απο το νερό. Όσο μικρότερος είναι ο πόρος φίλτρανσης του τόσο καλύτερο φιλτράρισμα του νερού γίνεται.",
    ],
    info: [
      "Υλικό: Πολυπροπυλένιο",
      "Μέγιστη πίεση: 6 bar",
      "Μέγιστη θερμοκρασία 60 C°",
      "Πόρος Φίλτρανσης: 1-50micron",
      "Efficiency 90-95 %",
      "Εσωτερική διάμετρος: 28mm",
      "Εξωτερική διάμετρος: 64mm ",
    ],
    available: true,
    group: 2,
  },
  {
    name: "Φίλτρο κοκκώδους άνθρακα",
    img: "https://www.veluda.com/assets/pages/main_58340-gac-e.jpg",
    type: "Φίλτρο κοκκώδους άνθρακα (GAC)",
    description: [
      "Το GAC είναι φίλτρο κοκκοειδή άνθρακα από φλοιό καρύδας.",
      "Τα φίλτρα κοκκοειδούς ενεργού άνθρακα απο φλοιό καρύδας είναι  100% φυσικό προϊόν. Έχουν σχεδιαστεί για να  παρέχουν χαμηλότερη πτώση πίεσης  σε σχέση με τα αντίστοιχα φίλτρα συμπαγούς άνθρακα,  μειώνουν αποτελεσματικά το χλώριο, τα φυτοφάρμακα, τα βαρέα μέταλλα και. Απορροφά τις ανεπιθύμητες γεύσεις και οσμές, συμπεριλαμβανομένης της γεύσης και της οσμής του χλωρίου από το πόσιμο νερό. Η μορφή τους σε σκόνη επιτρέπει την μέγιστη επαφή μεταξύ νερού και άνθρακα, εξασφαλίζοντας βέλτιστη διήθηση πόσιμου νερού. Διατίθενται σε μεγάλη ποικιλία μεγεθών και ταιριάζουν στις περισσότερες φιλτροθήκες νερού του εμπορίου ανάλογα τις διαστάσεις τους.",
    ],
    info: [
      "Υλικό: Κοκκώδης Άνθρακας από φλοιό καρύδας",
      "Μέγιστη πίεση: 6 bar",
      "Μέγιστη θερμοκρασία 60 C°",
      "Πόρος Φίλτρανσης: -",
      "Εσωτερική διάμετρος: 28mm",
      "Εξωτερική διάμετρος: 70mm (slim) & 113mm (big blue)",
    ],
    available: true,
    group: 2,
  },
];
